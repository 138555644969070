import React from 'react'
import { FindOptionByValue } from '../../../util/helper'
import VolunteeringOptions from '../../../assets/data/volunteering-options'
import ShirtSizeOptions from '../../../assets/data/shirt-size-options'

const Other = (props) => {
    return (
        <>
            <div className="field">
                <label>Volunteering:</label>
                <span>{FindOptionByValue(VolunteeringOptions, props.volunteering).label}</span>
            </div>
            <div className="field">
                <label>Tshirt Size:</label>
                <span>{FindOptionByValue(ShirtSizeOptions, props.shirtSize).label}</span>
            </div>
            <div className="field span-2">
                <span>
                    <label>How did you hear about us?</label><br />
                    {props.aboutUs}
                </span>
            </div>
            <div className="span-2">
                <div className="field">
                    <label>Interests</label><br />
                </div>
                <div dangerouslySetInnerHTML={{ __html: props.interest }}></div>
            </div>
            <div className="span-2">
                <div className="field">
                    <label>Additional Info</label><br />
                </div>
                <div dangerouslySetInnerHTML={{ __html: props.programValue }}></div>
            </div>
        </>
    )
}

export default Other
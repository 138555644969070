import React from 'react'
import './invoice.styles.scss'

const Invoice = (props) => {
    return (
        <div className="invoice">
            <p><strong>#{props.number}</strong></p>
            <p>{props.username}</p>
            <p>{props.description}</p>
            <div className="summary">
                <p>
                    Amount<br/>
                    <span>${props.amount}</span>
                </p>
                <p>
                    Paid<br/>
                    <span>${props.paid}</span>
                </p>
                <p>
                    Balance <br/>
                    <span>${props.balance}</span>
                </p>
            </div>
        </div>
    )
}

export default Invoice
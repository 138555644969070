import {postRequest} from '../../../../util/RequestHelper'

export const ActionActivateMembership = instance => {
    postRequest({
        url: "/api/admin/membership/activate",
        data: {
            id: instance.props.match.params.id
        },
        success: response => instance.setState({
            working: false,
            response_code: 200
        }),
        6001: response => instance.setState({
            working: false,
            response_code: 400
        }),
        6000: response => instance.setState({
            working: false,
            response_code: 500
        }),
        failure: error => instance.setState({
            working: false,
            response_code: 500
        }, console.log(error))
    })
}
import React from 'react'
import './upload.styles.scss'
import UploadForm from './form.component'
import UploadHelper from './helper'
import Uploading from './uploading.component'

class Upload extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.getDefaultState()
    }

    getDefaultState = () => {
        return {
            tab: 0,
            working: false,
            message: "",
            title: "",
            keywords: "",
            video: false,
            image: false,
            premiumMember: false,
            videoUploaded: false,
            thumbnailUploaded: false,
            errors: {}
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        if(this.isValid()){
            UploadHelper.createVideo(this)
        }
    }

    isValid = () => {
        if(this.state.title.length < 5){
            alert('enter title')
            return false
        }
        else if(this.state.image === false){
            alert('you must upload image')
            return false
        }
        else if(this.state.video === false){
            alert('you must upload video')
            return false
        }
        return true
    }

    onFileChange = (e) => {
        let files = [...e.target.files]
        if (files.length === 0) {
            return
        }
        this.setState({
            ...this.state,
            message: "",
            [e.target.name]: files[0]
        })
    }

    onCheckboxChange = (name, checked) => {
        this.setState({
            ...this.state,
            [name]: checked
        })
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onVideoCreated = (urls) => {
        this.setState({
            ...this.state,
            tab: 1,
            working: false,
            urls: urls
        })
    }

    onVideoCreateError = (error) => {
        console.log(error)
        this.setState({
            ...this.state,
            message: error
        })
    }

    onUploadCompleted = (name, response) => {
        response.status === 200 && this.setState({
            ...this.state,
            [name === "thumbnail" ? "thumbnailUploaded" : "videoUploaded"]: true
        }, () => {
            this.state.videoUploaded && this.state.thumbnailUploaded && this.setState({
                ...this.getDefaultState(),
                tab: 2
            })
        })
    }

    getComponentByTab = () => {
        let component = null;
        switch (this.state.tab) {
            case 0:
                component = <UploadForm onSubmit={this.onSubmit} onChange={this.onChange} onFileChange={this.onFileChange} onCheckboxChange={this.onCheckboxChange} {...this.state} />
                break
            case 1:
                component = <>
                    <Uploading title="Cover Image" name="thumbnail" value={this.state.image} url={this.state.urls['thumbnail']} onUploadCompleted={this.onUploadCompleted} />
                    <Uploading title="Video" name="video" value={this.state.video} url={this.state.urls['video']} onUploadCompleted={this.onUploadCompleted} />
                </>
                break
            case 2:
            default:
                component = <>
                    <h4>Upload Complete</h4>
                    <button className="btn" onClick={() => this.setState({
                        ...this.state,
                        tab: 0
                    })}>New Upload</button>
                </>
        }
        return component
    }

    render() {
        return (
            <div className="upload card">
                {this.getComponentByTab()}
            </div>
        )
    }
}

export default Upload

import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import './preview.styles.scss'
import Passport from './passport.component'
import Educcation from './education.component'
import Work from './work.component'
import Travel from './travel.component'
import Sponsor from './sponsor.component'
import Other from './other.component'
import Personal from './personal.component'
import { PreviewHelper } from './helper'

class Preview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: true
        }
    }

    componentDidMount(){
        this.loadApplication()
    }

    loadApplication() {
        PreviewHelper.getApplication(this)
    }

    hanldeLoadResponse(response) {
        this.setState({
            ...this.state,
            working: false,
            application: response.application
        })
    }

    handleLoadError(error) {
        this.setState({
            ...this.state,
            working: false
        })
    }

    render() {
        if(this.state.working){
            return (
                <div>loading...</div>
            )
        }
        return (
            <div className="preview">
                <div className="print-area">
                    <h2>My Application</h2>
                    <div className="field span-2">
                        <img alt="" src={this.state.application.data.photo} />
                    </div>
                    <h4>Personal Data</h4>
                    <Personal {...this.state.application.personal}/>
                    <br /><br />
                    <h4>Education Data</h4>
                    {
                        this.state.application.number !== "4"?
                        <Educcation {...this.state.application.education}/>:
                        <div>Not Available</div>
                    }
                    <br />
                    <h4>Work Data</h4>
                    <Work {...this.state.application.work}/>
                    <br />
                    <h4>Travel Data</h4>
                    <Travel {...this.state.application.travel}/>
                    <br />
                    <h4>Sponsor Data</h4>
                    <Sponsor {...this.state.application.sponsor}/>
                    <br /><br />
                    <h4>Passport Data</h4>
                    <Passport {...this.state.application.passport}/>
                    <br />
                    <h4>Others</h4>
                    <Other {...this.state.application.other}/>
                </div>
                <div className="actions">
                    <Link to={'/applications'}>Back</Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        application: state.application
    }
}

export default connect(mapStateToProps)(withRouter(Preview))
import {postRequest} from '../../../../util/RequestHelper'

const UploadHelper = {
    createVideo: instance => {
        postRequest({
            url: "/api/admin/media/videos/create",
            data: {
                title: instance.state.title,
                keywords: instance.state.keywords,
                premiumMember: instance.state.premiumMember
            },
            success: response => instance.onVideoCreated(response),
            6000: message => instance.onVideoCreateError(message),
            failure: error => instance.onVideoCreateError(error)
        })
    }
}

export default UploadHelper
const TravelModeOptions =  [
    {
        value: "0",
        label: "Plane"
    },
    {
        value: "1",
        label: "Bus/Train"
    }
    ,
    {
        value: "2",
        label: "Car"
    },
    {
        value: "3",
        label: "Others"
    }
]

export default TravelModeOptions
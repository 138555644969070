import React from 'react'
import { Link } from 'react-router-dom'
import EastcoinHelper from './helper'
import './eastcoins.styles.scss'

class Eastcoins extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: true,
            eastcoins: [],
        }
    }

    componentDidMount() {
        EastcoinHelper.getEastcoin(this)
    }

    onEastcoinsLoaded = (response) => {
        this.setState({
            ...this.state,
            eastcoins: response.eastcoins
        })
    }

    onEastcoinLoadingError = message => {

    }

    render() {
        return (
            <>
                <Link className="btn" to="/products/eastcoins/credit">Credit Member</Link>
                <div className="uploaded">
                    <br />
                    <table className="table table-light table-striped">
                        <thead className="table-dark">
                            <tr>
                                <th>User</th>
                                <th>Credit</th>
                                <th>Description</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.eastcoins.map((eastcoin, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{eastcoin.userId}</td>
                                            <td>{eastcoin.credit}</td>
                                            <td>{eastcoin.description}</td>
                                            <td>{new Date(eastcoin.creationDate).toString("dd MMM, yyyy")}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default Eastcoins
import React from 'react'
import { FindOptionByValue } from '../../../util/helper'
import MaritalStatusOptions from '../../../assets/data/marital-status-options'

const Personal = (props) => {
    return (
        <>
            <div className="field">
                <label>Firstname:</label>
                <span>{props.firstname}</span>
            </div>
            <div className="field">
                <label>Lastname:</label>
                <span>{props.lastname}</span>
            </div>
            <div className="field">
                <label>Phone:</label>
                <span>{props.phone}</span>
            </div>
            <div className="field">
                <label>Gender:</label>
                <span>{props.gender}</span>
            </div>
            <div className="field">
                <label>Marital Status:</label>
                <span>{FindOptionByValue(MaritalStatusOptions, props.maritalStatus).label}</span>
            </div>
            <div className="field">
                <label>Date of Birth:</label>
                <span>{props.dob}</span>
            </div>
            <div className="field">
                <label>Address:</label>
                <span>{props.address} {props.landmark}</span>
            </div>
            <div className="field">
                <label>City:</label>
                <span>{props.city}</span>
            </div>
            <div className="field">
                <label>State:</label>
                <span>{props.state}</span>
            </div>
            <div className="field">
                <label>Zipcode:</label>
                <span>{props.zipcode}</span>
            </div>
            <div className="field">
                <label>Country:</label>
                <span>{props.country}</span>
            </div>
        </>
    )
}

export default Personal
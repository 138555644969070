import {postRequest} from '../../../../util/RequestHelper'

const MeetingHelper = {
    schedule: instance => {
        postRequest({
            url: "/api/admin/media/meetings/create",
            data: instance.state,
            success: response => instance.onMeetingCreated(response),
            6000: message => instance.onMeetingCreateError(message),
            failure: error => instance.onMeetingCreateError(error)
        })
    }
}

export default MeetingHelper
import React from 'react'
import { Switch, Route } from "react-router-dom"
import EastcoinController from './eastcoin/eastcoin.controller'

const ProductController = () => {
    return (
        <div id="apps" className="apps">
            <Switch>
                <Route path="/products/eastcoins" component={EastcoinController} />
            </Switch>
        </div>
    )
}

export default ProductController
import React from 'react'
import { Link } from 'react-router-dom'
import MeetingsHelper from './helper'
import './meetings.styles.scss'

class Meetings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: true,
            meetings: [],
            deletedMeetings: []
        }
    }

    componentDidMount() {
        MeetingsHelper.getMeetings(this)
    }

    onMeetingsLoaded = (response) => {
        this.setState({
            ...this.state,
            meetings: response.meetings
        })
    }

    onMeetingDeleteError = (error) => {
        alert(error)
    }

    deleteMeeting = (key) => {
        let deletedMeetings = this.state.deletedMeetings
        deletedMeetings.push(this.state.meetings[key].id)
        this.setState({
            ...this.state,
            deletedMeetings: deletedMeetings
        })
        MeetingsHelper.deleteMeeting(this, this.state.meetings[key].id)
    }

    getActionComponent = (meetingId, key) => {
        if (this.state.deletedMeetings.indexOf(meetingId) === -1) {
            return (
                <button onClick={() => this.deleteMeeting(key)}>Delete</button>
            )
        }
        else {
            return (
                <strong><span></span></strong>
            )
        }
    }

    onMeetingDeleted = (meetingId) => {
        let deletedMeetings = this.state.deletedMeetings.filter(deletedMeetingId => deletedMeetingId !== meetingId)
        let meetings = this.state.meetings.filter(meeting => meeting.id !== meetingId)
        this.setState({
            ...this.state,
            meetings: meetings,
            deletedMeetings: deletedMeetings
        })
    }

    onMeetingDeleteError = (meetingId, error) => {
        alert(error)
        let deletedMeetings = this.state.deletedMeetings.filter(deletedMeetingId => deletedMeetingId !== meetingId)
        this.setState({
            ...this.state,
            deletedMeetings: deletedMeetings
        })
    }

    render() {
        return (
            <>
                <Link className="btn" to="/apps/meetings/schedule">Schedule Meeting</Link>
                <div className="scheduled">
                    <br />
                    <table className="table table-light table-striped">
                        <thead className="table-dark">
                            <tr>
                                <th>Topic</th>
                                <th>Start Date</th>
                                <th>Url</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.meetings.map((meeting, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{meeting.topic}</td>
                                            <td>{new Date(meeting.startDate).toString("HH:mm dd MMM, yyyy")}</td>
                                            <td><a href={meeting.startUrl}>start</a></td>
                                            <td>
                                                {this.getActionComponent(meeting.id, key)}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default Meetings
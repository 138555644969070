import React from 'react'
import './dashboard.styles.scss'
import imageCoin from '../../assets/images/coin.png'
import { withRouter } from 'react-router-dom'
import { DashboardHelper } from './dashboard-helper'

class Dashboard extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            working: true
        }
    }

    componentDidMount(){
        DashboardHelper.getAnalysis(this)
    }

    render() {
        if(this.state.working){
            return (
                <div>loading...</div>
            )
        }
        return (
            <div className="dashboard">
                <div className="cards">
                    <div className="card program" onClick={() => this.props.history.push("/applications")}>
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Applications</strong>
                            <span>{this.state.analysis.applications}</span>
                        </div>
                    </div>
                    <div className="card applications" onClick={() => this.props.history.push("/users/registrations")}>
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Registrations</strong>
                            <span>{this.state.analysis.registrations}</span>
                        </div>
                    </div>
                    <div className="card points">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Travel Points</strong>
                            <span>{this.state.analysis.travelPoints}</span>
                        </div>
                    </div>
                    <div className="card referrals">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Referrals</strong>
                            <span>{this.state.analysis.userReferrals}</span>
                        </div>
                    </div>
                    <div className="card membership" onClick={() => this.props.history.push("/users/membership")}>
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Membership</strong>
                            <span>{this.state.analysis.membership}</span>
                        </div>
                    </div>
                    <div className="card payments">
                        <div>
                            <img alt="" src={imageCoin} />
                        </div>
                        <div>
                            <strong>Pending Payments</strong>
                            <span>none</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Dashboard)

export const FindOptionByValue = (opitons, value) => {
    for (let i = 0; i < opitons.length; i++) {
        if(opitons[i].value === value){
            return opitons[i]
        }
    }
    return {
        label: "",
        value: ""
    }
}

export const ScrollIntoViewById = (id) => {
    let element = document.getElementById(id);
    if (element != null) {
        element.scrollIntoView({ 'behavior': 'smooth' })
    }
}
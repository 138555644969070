import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import store from './redux/stores/store'
import { Provider } from 'react-redux'
import { EmitProvider } from "react-emit"
import './App.scss'
import Secured from './pages/secured/secured'
import UnSecured from './pages/unsecured/unsecured'

const App = () => {
  return (
    <Provider store={store}>
      <EmitProvider>
        <Router>
          <Switch>
            <Route path="/login" component={UnSecured} />
            <Route path="/reset" component={UnSecured} />
            <Route path="/recovery" component={UnSecured} />
            <Route path="/" component={Secured} />
          </Switch>
        </Router>
      </EmitProvider>
    </Provider>
  )
}
export default App

import React from 'react'
import './application.styles.scss'
import { Link } from 'react-router-dom'
import ApplicationProgramLabels from '../../../../assets/data/application-program-labels'

const Application = (props) => {
    return (
        <Link className="application" to={'/applications/' + props.number}>
            <img src={props.photo} alt="" />
            <div>
                <h4>
                    Number: {props.number}
                </h4>
                <p>{ApplicationProgramLabels[props.program]}</p>
            </div>
        </Link>
    )
}

export default Application
import React from 'react'
import './header.styles.scss'
import Brand from './brand/brand.component'
import SearchBox from './search-box/search-box.component'
import Auth from './auth/auth.component'

const Header = () => {
    return (
        <header>
            <Brand />
            <SearchBox />
            <Auth />
        </header>
    )
}

export default Header
import React from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { API_ENDPOINT } from '../../../config'

class Recovery extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: false,
            message: "Enter new password",
            username: "",
            password: ""
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        if(this.state.working){
            return
        }
        if (this.state.password.length < 6 || this.state.password.length > 40) {
            this.setState({
                ...this.state,
                message: "Password Invalid"
            })
            return
        }
        if (this.state.password !== this.state.password2) {
            this.setState({
                ...this.state,
                message: "Passwords do not match"
            })
            return
        }
        this.change()
    }

    change = () => {
        this.setState({
            ...this.state,
            working: true
        })
        let data = {
            hash: this.props.match.params.hash,
            password: this.state.password
        }
        axios.defaults.withCredentials = true
        axios.post(API_ENDPOINT + "/api/auth/change", data)
            .then(response => this.handleChangePasswordResponse(response))
            .catch(error => this.handleChangePasswordError(error))
    }

    handleChangePasswordResponse = (response) => {
        if (response.status === 200 && response.data.status === 200) {
            this.setState({
                ...this.state,
                working: false,
                password: "",
                password2: "",
                message: "Password Updated"
            })
            let interval = setInterval(() => {
                this.props.history.push("/login")
                clearInterval(interval)
            }, 2000)
        }
        else {
            this.setState({
                ...this.state,
                working: false,
                message: response.data.message
            })
        }
    }

    handleChangePasswordError = (error) => {
        this.setState({
            ...this.state,
            working: false,
            message: error.toString()
        })
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <div className="signin">
                <div className="top">
                    <span>Password Update</span> <span>or <b onClick={() => this.props.history.push("/login")}>login</b></span>
                </div>
                <form onSubmit={this.onSubmit}>
                    <h4 className="message">{this.state.message}</h4>
                    <div className="form-input">
                        <label>New Password</label>
                        <input type="password" name="password" value={this.state.password} onChange={this.onChange} />
                    </div>
                    <div className="form-input">
                        <label>Confirm Password</label>
                        <input type="password" name="password2" value={this.state.password2} onChange={this.onChange} />
                    </div>
                    <div className="actions">
                        <button>{this.state.working ? "..." : "Update"}</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default withRouter(Recovery)
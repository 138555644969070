import React from 'react'
import { connect } from 'react-redux'
import './brand.styles.scss'
import logoLight from '../../../assets/images/logo-light.png'
import { TOGGLE_SHOW_MENU } from '../../../redux/reducers/menu/action-types'

const Brand = (props) => {
    const onClick = () => {
        props.dispatch({
            type: TOGGLE_SHOW_MENU,
            payload: {}
        })
    }
    return (
        <div className="brand">
            <div>
                <img alt="" src={logoLight} />
                <div className="toggler" onClick={onClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state.menu
}

export default connect(mapStateToProps)(Brand)
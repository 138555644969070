import {getRequest, postRequest} from '../../../util/RequestHelper'

const VideosHelper = {
    getVideos: instance => {
        getRequest({
            url: "/api/admin/media/videos",
            success: response => instance.onVideosLoaded(response),
            6000: message => instance.onVideosLoadingError(message),
            failure: error => instance.onVideosLoadingError(error)
        })
    },
    updateVideo: (instance, video) => {
        postRequest({
            url: "/api/admin/media/videos/update",
            data: {
                id: video.id,
                accessLevel: video.accessLevel 
            },
            success: response => {},
            6000: message => instance.onVideoUpdateError(video.id, message),
            failure: error => instance.onVideoUpdateError(video.id, error)
        })
    },
    deleteVideo: (instance, videoId) => {
        postRequest({
            url: "/api/admin/media/videos/delete",
            data: {
                id: videoId
            },
            success: response => instance.onVideoDeleted(videoId),
            6000: message => instance.onVideoDeleteError(videoId, message),
            failure: error => instance.onVideoDeleteError(videoId, error)
        })
    }
}

export default VideosHelper
import React from 'react'
import { Switch, Route } from "react-router-dom"
import MembershipList from './list/list.component'

const MembershipController = () => {
    console.log('called')
    return (
        <div id="membership" className="membership">
            <Switch>
                <Route path="/users/membership" component={MembershipList} />
            </Switch>
        </div>
    )
}

export default MembershipController
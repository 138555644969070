const DefaultStore = {
    institution:"",
    course: "",
    level: "",
    graduationDate: "",
    graduated: true,
    englishLanguage: {
        label: "",
        value: ""
    },
    skills: ""
}

export default DefaultStore
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './auth.styles.scss'
import accountAvatar from '../../../assets/images/account-avatar.jpg'
import arrowDownIcon from '../../../assets/icons/arrow-down.svg'

const Auth = () => {
    return (
        <div className="auth">
            <div>
                <div className="img-cont">
                    <img src={accountAvatar} alt="" />
                </div>
                <strong>{window.sessionStorage.getItem('auth_name')}</strong>
                <img className="arrow-down" src={arrowDownIcon} alt="" />
                <div className="clearfix"></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Auth))
import React from 'react'
import { Switch, Route } from "react-router-dom"
import RegistrationList from './list/list.component'
import ActivateMembership from './activate-membership/activate-membership.component'

const RegistrationController = () => {
    return (
        <div id="registrations" className="registrations">
            <Switch>
                <Route path="/users/registrations/activate/membership/:id" component={ActivateMembership} />
                <Route exact path="/users/registrations" component={RegistrationList} />
            </Switch>
        </div>
    )
}

export default RegistrationController
import React from 'react'
import './schedule.styles.scss'
import ScheduleForm from './form.component'
import MeetingHelper from './helper'
import { Link } from 'react-router-dom'
import { Validation } from '../../../../core/validations/validation'
import { StringValidationRule } from '../../../../core/validations/validation-rules'

class Schedule extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.getDefaultState()
    }

    getDefaultState = () => {
        return {
            working: false,
            message: "",
            topic: "",
            description: "",
            startDate: "",
            startHour: "",
            startMinute: "",
            duration: "",
            password: "",
            agenda: "",
            reminder: "",
            newsletterSubject: "",
            hostVideo: false,
            participantVideo: false,
            joinBeforeHost: false,
            premiumMember: false,
            errors: {}
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        if (this.isValid()) {
            MeetingHelper.schedule(this)
            this.setState({
                ...this.state,
                working: true
            })
        }
    }

    isValid = () => {
        let errors = {}
        let validation = new Validation()
        validation.addValidationRule(StringValidationRule, this.state.topic, (error) => errors.topic = error, { min: { value: 5, error: "Too short" }, max: { value: 150, error: "Too long" } })
        validation.addValidationRule(StringValidationRule, this.state.description, (error) => errors.description = error, { min: { value: 5, error: "Too short" }, max: { value: 300, error: "Too long" } })
        validation.addValidationRule(StringValidationRule, this.state.startHour, (error) => errors.startHour = error, { min: { value: 1, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
        validation.addValidationRule(StringValidationRule, this.state.startDate, (error) => errors.startDate = error, { min: { value: 10, error: "Invalid" }, max: { value: 10, error: "Invalid" } })
        validation.addValidationRule(StringValidationRule, this.state.startMinute, (error) => errors.startMinute = error, { min: { value: 1, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
        validation.addValidationRule(StringValidationRule, this.state.duration, (error) => errors.duration = error, { min: { value: 1, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
        validation.addValidationRule(StringValidationRule, this.state.reminder, (error) => errors.reminder = error, { min: { value: 1, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
        validation.addValidationRule(StringValidationRule, this.state.password, (error) => errors.password = error, { min: { value: 6, error: "Too short" }, max: { value: 10, error: "Too long" } })
        validation.addValidationRule(StringValidationRule, this.state.agenda, (error) => errors.agenda = error, { min: { value: 5, error: "Too short" }, max: { value: 1000, error: "Too long" } })
        validation.addValidationRule(StringValidationRule, this.state.newsletterSubject, (error) => errors.newsletterSubject = error, { min: { value: 5, error: "Too short" }, max: { value: 150, error: "Too long" } })
        this.setState({
            ...this.state,
            errors: errors
        })
        return validation.validate()
    }

    onCheckboxChange = (name, checked) => {
        this.setState({
            ...this.state,
            [name]: checked
        })
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onMeetingCreated = () => {
        this.setState({
            ...this.getDefaultState(),
            message: "Scheduled Successfully"
        })
    }

    onMeetingCreateError = (error) => {
        console.log(error)
        this.setState({
            ...this.state,
            message: error
        })
    }

    render() {
        return (
            <>
                <Link className="btn" to="/apps/meetings">Meetings</Link>
                <div className="scheduler card">
                    <ScheduleForm onSubmit={this.onSubmit} onChange={this.onChange} onCheckboxChange={this.onCheckboxChange} {...this.state} />
                </div>
            </>
        )
    }
}

export default Schedule

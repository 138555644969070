import React from 'react'
import { Switch, Route } from "react-router-dom"
import Applications from './applications/applications.component'
import Preview from './preview/preview.component'

const Application = () => {
    return (
        <div id="applications" className="applications">
            <Switch>
                <Route path="/applications/:number" component={Preview} />
                <Route exact path="/applications" component={Applications} />
            </Switch>
        </div>
    )
}

export default Application
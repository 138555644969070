import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Videos from './videos.component'
import VideoUploader from './uploader.component'

const VideoController = () => {
    return (
        <div className="videos">
            <Switch>
                <Route path="/apps/videos/uploader" component={VideoUploader} />
                <Route exact path="/apps/videos" component={Videos} />
            </Switch>
        </div>
    )
}

export default VideoController
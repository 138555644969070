const MaritalStatusOptions = [
    {
        value: "0",
        label: "Single"
    },
    {
        value: "1",
        label: "Married"
    }
]

export default MaritalStatusOptions
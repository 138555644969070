const DefaultStore = {
    departureDate: "",
    returnDate: "",
    destination: "",
    preferredSchool: "",
    requireVisa: {
        label: "",
        value: ""
    },
    budget: "",
    adults: "",
    children: "",
    requireTourGuide: {
        label: "",
        value: ""
    },
    tourDuration: "",
    travelMode: {
        label: "",
        value: ""
    },
    otherTravelMode: "",
    travelHistory: "",
    additionalInfo: ""
}

export default DefaultStore
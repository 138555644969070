import React from 'react'
import './account-access.styles.scss'
import Login from './login/login.component'
import ForgotPassword from './forgot-pasword/forgot-password.component'
import Recovery from './recovery/recovery.component'
import { Switch, Route } from 'react-router-dom'

const AccountAccess = () => {
    return (
        <div className="account-access">
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/recovery/:hash" component={Recovery} />
                <Route path="/reset" component={ForgotPassword} />
            </Switch>
        </div>
    )
}

export default AccountAccess
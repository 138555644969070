import React from 'react'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <h4 className="message">{props.message}</h4>
            <div className="form-input">
                <label>Email or Username</label>
                <input type="email" name="username" value={props.username} onChange={props.onChange} />
            </div>
            <div className="actions">
                {
                    props.working?
                    <button>...</button>:
                    <button>Reset</button>
                }
            </div>
        </form>
    )
}

export default Form
import React from 'react'
import { withEmit } from "react-emit"
import Item from './item/item.component'
import { GetMembership } from './helper'
import { SEARCH_CHANGED } from '../../../events/header/search-box'

class MembershipList extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            working: true,
            filter: ""
        }
    }

    componentDidMount(){
        GetMembership(this)
        this.props.on(SEARCH_CHANGED, (data) => {
            this.setState({
                ...this.state,
                filter: data
            })
        })
    }

    componentWillUnmount(){
        this.props.off(SEARCH_CHANGED)
    }

    render(){
        if(this.state.working){
            return (
                <div>loading...</div>
            )
        }
        return (
            <div className="items">
                {
                    this.state.items.filter(item => item.email.toLowerCase().includes(this.state.filter) || item.firstname.toLowerCase().includes(this.state.filter) || item.lastname.toLowerCase().includes(this.state.filter)).map((item, key) => <Item key={key} {...item}/>)
                }
            </div>
        )
    }
}

export default withEmit(MembershipList)
import React from 'react'

const Sponsor = (props) => {
    return (
        <>
            <div className="field">
                <label>Firstname:</label>
                <span>{props.firstname}</span>
            </div>
            <div className="field">
                <label>Lastname:</label>
                <span>{props.lastname}</span>
            </div>
            <div className="field">
                <label>Phone:</label>
                <span>{props.phone}</span>
            </div>
            <div className="field">
                <label>Email:</label>
                <span>{props.email}</span>
            </div>
            <div className="field">
                <label>Relationship:</label>
                <span>{props.relationship}</span>
            </div>
        </>
    )
}

export default Sponsor
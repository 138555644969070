import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './unsecured.scss'
import AccountAccess from '../../components/account-access/account-access.component'
import imageWorld from '../../assets/images/world.png'
import imagePlane from '../../assets/images/plane.png'

class UnSecuredPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loggedIn: true
        }
    }

    render() {
        return (
            <div className="unsecured">
                <header></header>
                <div className="forms">
                    <div className="quote">
                        <div>
                            <p>
                                Don't leave the earth <span></span>without traveling <span></span>around it
                                </p>
                            <strong>- Careca Akarue - CEO Eurufly Limited</strong>
                        </div>
                        <div>
                            <img alt="" src={imageWorld} />
                            <img alt="" src={imagePlane} />
                        </div>
                    </div>
                    <AccountAccess />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps)(withRouter(UnSecuredPage))
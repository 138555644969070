import React from 'react'
import { FindOptionByValue } from '../../../util/helper'
import EnglishLanguageOptions from '../../../assets/data/english-language'

const Educcation = (props) => {
    return (
        <>
            <div className="field">
                <label>Institution:</label>
                <span>{props.institution}</span>
            </div>
            <div className="field">
                <label>Course:</label>
                <span>{props.course}</span>
            </div>
            <div className="field">
                <label>Level:</label>
                <span>{props.level}</span>
            </div>
            <div className="field">
                <label>Graduation Date:</label>
                <span>{props.graduationDate}</span>
            </div>
            <div className="field">
                <label>English Language:</label>
                <span>{FindOptionByValue(EnglishLanguageOptions, props.englishLanguage).label}</span>
            </div>
            <div className="field">
                <label>Skills:</label>
                <span>{props.skills}</span>
            </div>
        </>
    )
}

export default Educcation
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { ActionActivateMembership } from './helper'

class ActivateMembership extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: true
        }
    }

    componentDidMount() {
        ActionActivateMembership(this)
    }

    render() {
        if (this.state.working) {
            return (
                <div>
                    activating membership...
                </div>
            )
        }
        switch (this.state.response_code) {
            case 200:
                return (
                    <div>
                        <p>Membership Activated</p>
                        <br/>
                        <Link className="btn" to="/users/membership">View Membership</Link>
                    </div>
                )
            case 400:
                return (
                    <div>
                        <p>Membership Already Exist</p>
                        <br/>
                        <Link className="btn" to="/users/registrations">Go Back</Link>
                    </div>
                )
                default:
                    return (
                        <div>
                            <p>An error occurred</p>
                            <br/>
                            <Link className="btn" to="/users/registrations">Go Back</Link>
                        </div>
                    )
        }
    }
}

export default withRouter(ActivateMembership)
import {getRequest} from '../../../util/RequestHelper'

export const ApplicationsHelper = {
    getApplications: instance => {
        getRequest({
            url: "/api/admin/applications/list",
            success: response => instance.setState({
                ...instance.state,
                working: false,
                applications: response.applications
            }),
            failure: error => instance.setState({
                ...instance.state,
                working: false
            }, console.log(error))
        })
    }
}
import React from 'react'
import { Link } from 'react-router-dom'
import Checkbox from '../../inputs/checkbox/checkbox.component'
import VideosHelper from './helper'
import './videos.styles.scss'

class Videos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: true,
            videos: [],
            deletedVideos: []
        }
    }

    componentDidMount() {
        VideosHelper.getVideos(this)
    }

    onVideosLoaded = (response) => {
        this.setState({
            ...this.state,
            videos: response.videos
        })
    }

    onVideoDeleteError = (error) => {
        alert(error)
    }

    deleteVideo = (key) => {
        let deletedVideos = this.state.deletedVideos
        deletedVideos.push(this.state.videos[key].id)
        this.setState({
            ...this.state,
            deletedVideos: deletedVideos
        })
        VideosHelper.deleteVideo(this, this.state.videos[key].id)
    }

    getActionComponent = (videoId, key) => {
        if (this.state.deletedVideos.indexOf(videoId) === -1) {
            return (
                <button onClick={() => this.deleteVideo(key)}>Delete</button>
            )
        }
        else {
            return (
                <strong><span></span></strong>
            )
        }
    }

    onVideoDeleted = (videoId) => {
        let deletedVideos = this.state.deletedVideos.filter(deletedVideoId => deletedVideoId !== videoId)
        let videos = this.state.videos.filter(video => video.id !== videoId)
        this.setState({
            ...this.state,
            videos: videos,
            deletedVideos: deletedVideos
        })
    }

    onVideoDeleteError = (videoId, error) => {
        alert(error)
        let deletedVideos = this.state.deletedVideos.filter(deletedVideoId => deletedVideoId !== videoId)
        this.setState({
            ...this.state,
            deletedVideos: deletedVideos
        })
    }

    onAccessLevelChange = (key, checked) => {
        let videos = this.state.videos
        videos[key].accessLevel = checked
        this.setState({
            ...this.state,
            videos: videos
        })
        VideosHelper.updateVideo(this, videos[key])
    }

    onVideoUpdateError = (videoId, error) => {}

    render() {
        return (
            <>
                <Link className="btn" to="/apps/videos/uploader">Upload</Link>
                <div className="uploaded">
                    <br />
                    <table className="table table-light table-striped">
                        <thead className="table-dark">
                            <tr>
                                <th>Title</th>
                                <th>Premium</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.videos.map((video, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{video.title}</td>
                                            <td><Checkbox text="" name={key} checked={video.accessLevel} onChange={(name, checked) => this.onAccessLevelChange(name, checked)} /></td>
                                            <td>{new Date(video.creationDate).toString("dd MMM, yyyy")}</td>
                                            <td>
                                                {this.getActionComponent(video.id, key)}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default Videos
import {getRequest} from '../../../util/RequestHelper'

const EastcoinHelper = {
    getEastcoin: instance => {
        getRequest({
            url: "/api/products/eastcoin",
            success: response => instance.onEastcoinsLoaded(response),
            6000: message => instance.onEastcoinLoadingError(message),
            failure: error => instance.onEastcoinLoadingError(error)
        })
    }
}

export default EastcoinHelper
import React from 'react'
import { Switch, Route } from "react-router-dom"
import RegistrationController from './registration/controller.component'
import MembershipController from './membership/controller.component'

const UsersController = () => {
    return (
        <div id="users" className="users">
            <Switch>
                <Route path="/users/registrations" component={RegistrationController} />
                <Route path="/users/membership" component={MembershipController} />
            </Switch>
        </div>
    )
}

export default UsersController
import axios from 'axios'
import { API_ENDPOINT } from '../config'
import { IsLoggedIn, GetAuthorizationToken } from './AuthHelper'

export const getRequest = props => {
    axios({
        method: 'get',
        url: API_ENDPOINT + props.url,
        headers: {
            Authorization: IsLoggedIn() ? GetAuthorizationToken() : ''
        }
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            props.success(response.data.data)
        }
        else if (typeof props[response.data.status] != 'undefined') {
            props[response.data.status](response.data.message)
        }
    }).catch(error => props.failure(error))
}

export const postRequest = props => {
    axios({
        method: 'post',
        url: API_ENDPOINT + props.url,
        headers: {
            Authorization: IsLoggedIn() ? GetAuthorizationToken() : ''
        },
        data: props.data
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            props.success(response.data.data)
        }
        else if (typeof props[response.data.status] != 'undefined') {
            props[response.data.status](response.data.message)
        }
    }).catch(error => props.failure(error))
}
import React from 'react'
import {Link} from 'react-router-dom'
import iconArrowRight from '../../../assets/icons/arrow-right.svg'
import iconArrowRightBrown from '../../../assets/icons/arrow-right-brown.svg'

export const MenuItem = ({active, text, onClick, onSubItemClick, subItems}) => {
    return (
        <li className={active ? 'item active': 'item'}>
            <span className="item"  onClick={onClick}>
            <img src={active?iconArrowRightBrown:iconArrowRight} alt="" /> {text} 
            </span>
            <ul>
                {
                    subItems.map(
                    (menu, key) => <li key={key}><Link onClick={onSubItemClick} to={menu.url}><img src={iconArrowRight} alt=""/>{menu.text}</Link></li>
                    )
                }
            </ul>
        </li>
    )
}
import React from 'react'

class Payments extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            working: true
        }
    }

    render(){
        return(
            <div className="payments">
                Payments
            </div>
        )
    }
}

export default Payments
import React from 'react'

const Work = (props) => {
    return (
        <>
            <div className="field span-2">
                <span>
                    <label>Current Employer</label><br />
                    {props.currentEmployer}
                </span>
            </div>
            <div className="span-2">
                <div className="field">
                    <label>Working Experience</label><br />
                </div>
                <div dangerouslySetInnerHTML={{ __html: props.experience }}></div>
            </div>
        </>
    )
}

export default Work
import PersonalDefaultStore from './personal'
import EducationDefaultStore from './education'
import OtherDefaultStore from './other'
import PassportDefaultStore from './passport'
import SponsorDefaultStore from './sponsor'
import TravelDefaultStore from './travel'
import WorkDefaultStore from './work'

const DefaultStore = {
    personal: PersonalDefaultStore,
    education: EducationDefaultStore,
    other: OtherDefaultStore,
    passport: PassportDefaultStore,
    sponsor: SponsorDefaultStore,
    travel: TravelDefaultStore,
    work: WorkDefaultStore
}

export default DefaultStore
const VolunteeringOptions = [
    {
        value: "0",
        label: "None"
    },
    {
        value: "1",
        label: "Local"
    }
    ,
    {
        value: "2",
        label: "International"
    },
    {
        value: "3",
        label: "Local & International"
    }
]

export default VolunteeringOptions
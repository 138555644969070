import { combineReducers } from 'redux'
import menu from './menu/reducer'
import auth from './auth/reducer'
import application from './application/reducer'

const Reducer = combineReducers({
    menu,
    auth,
    application
})

export default Reducer
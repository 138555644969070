import React from 'react'
import './applications.styles.scss'
import Application from './application/application.component'
import { ApplicationsHelper } from './helper'

class Applications extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      working: true,
      applications: []
    }
  }

  componentDidMount() {
    ApplicationsHelper.getApplications(this)
  }

  render() {
    if(this.state.working){
      return (
        <div>loading...</div>
      )
    }
    return (
      <div className="all-applications">
        {
          this.state.applications.map((application, key) => <Application key={key} {...application} />)
        }
      </div>
    )
  }
}

export default Applications
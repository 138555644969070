import React from 'react'
import './progress-bar.styles.scss'

const ProgressBar = ({ progress }) => {
    return (
        <div className="progress-bar">
            <div className="progress" style={{width: progress + '%'}}>
                {
                    progress > 0 && progress + "%"
                }
            </div>
        </div>
    )
}

export default ProgressBar
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {LoginHelper} from './helper'
import { SaveAuth } from '../../../util/AuthHelper'

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: false,
            message: "Enter username and password",
            username: "",
            password: "",
            rememberMe: false
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.login()
    }

    login = () => {
        LoginHelper(this)
    }

    onAuthenticationSuccess = auth => {
        this.reset()
        SaveAuth(auth)
        this.props.history.push('/')
    }

    onAuthenticationFailure = message => {
        this.setState({
            ...this.state,
            working: false,
            message: message
        })
    }

    reset = () => {
        this.setState({
            ...this.state,
            loading: false,
            message: "Enter username and password",
            username: "",
            password: "",
            rememberMe: false
        })
    }

    handleLoginError = (error) => {
        this.setState({
            ...this.state,
            loading: false,
            message: error.toString()
        })
    }

    onChange = (e) => {
        if (e.target.type === 'checkbox') {
            this.setState({
                ...this.state,
                rememberMe: !this.state.rememberMe
            })
        }
        else {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value
            })
        }
    }

    render() {
        return (
            <div className="signin">
                <div className="top">
                    <span>Sign in</span>
                </div>
                <form onSubmit={this.onSubmit}>
                    <h4 className="message">{this.state.message}</h4>
                    <div className="form-input">
                        <label>Email or Username</label>
                        <input type="email" name="username" value={this.state.username} onChange={this.onChange} />
                    </div>
                    <div className="form-input">
                        <label>Password</label>
                        <input type="password" name="password" value={this.state.password} onChange={this.onChange} />
                    </div>
                    <div className="actions">
                        <label><input type="checkbox" name="rememberMe" checked={this.state.rememberMe} onChange={this.onChange} />Remember me</label>
                        <button>Login</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default connect(null)(withRouter(Login))
import React from 'react'
import { FindOptionByValue } from '../../../util/helper'
import CountryOptions from '../../../assets/data/countries'

const Passport = (props) => {
    return (
        <>
            <div className="field">
                <label>Number:</label>
                <span>{props.number}</span>
            </div>
            <div></div>
            <div className="field">
                <label>Issue Date:</label>
                <span>{new Date(props.issueDate).toString("dd MMM, yyyy")}</span>
            </div>
            <div className="field">
                <label>Expiry Date:</label>
                <span>{new Date(props.expiryDate).toString("dd MMM, yyyy")}</span>
            </div>
            <div className="field">
                <label>Place of Issue:</label>
                <span>{props.place}</span>
            </div>
            <div className="field">
                <label>Country:</label>
                <span>{FindOptionByValue(CountryOptions, props.country).label}</span>
            </div>
        </>
    )
}

export default Passport
import React from 'react'
import { Link } from 'react-router-dom'

const Form = (props) => {
    return (
        <form className="upload" onSubmit={props.onSubmit}>
            <h4>Credit Member</h4>
            {
                props.message.length > 0 && <h5 className="message">{props.message}</h5>
            }
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Username</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="username" value={props.username} onChange={props.onChange} />
                        <span className="error">{props.errors.username}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>EastCoin Value</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="value" value={props.value} onChange={props.onChange} />
                        <span className="error">{props.errors.value}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <Link to={'/products/eastcoins'}>Back</Link>
                {
                    props.working ?
                        <span className="btn-wait"></span> :
                        <button className="btn" type="submit">Send</button>
                }
            </div>
        </form>
    )
}

export default Form
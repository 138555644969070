const ShirtSizeOptions = [
    {
        value: "0",
        label: "Small"
    },
    {
        value: "1",
        label: "Medium"
    }
    ,
    {
        value: "2",
        label: "Large"
    },
    {
        value: "3",
        label: "Extra Large"
    }
]

export default ShirtSizeOptions
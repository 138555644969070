import React from 'react'
import { connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import './menu.styles.scss'
import store from '../../redux/stores/store'
import { MenuItem } from './menu-item/menu-item.component'
import {MENU_ITEM_CLICK, TOGGLE_SHOW_MENU} from '../../redux/reducers/menu/action-types'
import { RemoveAuth } from '../../util/AuthHelper'

class Menu extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            
        }
        this.unsubscribe = store.subscribe(() => {
            this.setState(store.getState().menu)
        })
    }

    componentWillUnmount = () => {
        this.unsubscribe()
    }

    componentDidMount = () => {
        this.setCurrentMenuItem()
    }

    onMenuItemClick = (key) => {
        key === 0 && this.props.history.push('/dashboard')
        this.fireActionOMenuItemClick(key)
    }

    fireActionOMenuItemClick(key){
        this.props.dispatch({
            type: MENU_ITEM_CLICK,
            payload: {
                key: key,
                active: !this.props.menus[key].active
            }
        })
    }

    fireActionToggleShowMenu = ()=> {
        this.props.dispatch({
            type: TOGGLE_SHOW_MENU,
            payload:{}
        })
    }

    setCurrentMenuItem = () => {
        this.props.menus.forEach((menu, key) => {
            menu.subItems.forEach(menuItem => {
                menuItem.url === this.props.location.pathname && this.onMenuItemClick(key)
            })
        })
    }

    logout = (e) => {
        e.preventDefault()
        RemoveAuth()
        this.props.history.push('/login')
    }

    render(){
        return(
            <div className={this.state.showMenu? 'menu active': 'menu'}>
                <h4>Navigation</h4>
                <ul>
                    {
                        this.props.menus.map(
                            (menu, key) => <MenuItem key={key} onClick={() => this.onMenuItemClick(key)} onSubItemClick={this.fireActionToggleShowMenu} {...menu}/>
                        )
                    }
                </ul>
                <Link onClick={this.logout} className="logout" to="/logout">Logout</Link>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state.menu
}

export default connect(mapStateToProps)(withRouter(Menu))
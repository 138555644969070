import React from 'react'
import { Switch, Route } from "react-router-dom"
import Newsletters from './newsletters.component'
import CreateNewsletter from './create.component'

const NewsletterController = () => {
    return (
        <div id="apps" className="apps">
            <Switch>
                <Route path="/apps/newsletters/create" component={CreateNewsletter} />
                <Route path="/apps/newsletters" component={Newsletters} />
            </Switch>
        </div>
    )
}

export default NewsletterController
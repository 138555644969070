export const SaveAuth = (auth) => {
    sessionStorage.setItem("auth_jwt", auth.jwt)
    sessionStorage.setItem("auth_name", auth.name)
}

export const RemoveAuth = () => {
    sessionStorage.removeItem("auth_jwt")
    sessionStorage.removeItem("auth_name")
}

export const IsLoggedIn = () => sessionStorage.getItem('auth_jwt') !== null

export const GetAuthName = () => sessionStorage.getItem('auth_name')

export const GetAuthorizationToken = () => sessionStorage.getItem('auth_jwt')
const DefaultStore = {
    aboutUs: "",
    volunteering: {
        label: "",
        value: ""
    },
    shirtSize: {
        label: "",
        value: ""
    },
    interest: "",
    programValue: ""
}
export default DefaultStore
import React from 'react'
import { getInvoices } from './helper'
import Invoice from './invoice/invoice.component'

class Invoices extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            working: true
        }
    }

    componentDidMount(){
        getInvoices(this)
    }

    onInvoicesReceived = response => {
        this.setState({
            ...this.state,
            working: false,
            ...response
        })
    }

    onError = message => {
        alert(message)
    }

    render(){
        if(this.state.working){
            return (
                <div>loading...</div>
            )
        }
        return(
            <div className="invoices">
                {
                    this.state.invoices.map((invoice, key) => <Invoice key={key} {...invoice}/>)
                }
            </div>
        )
    }
}

export default Invoices
import React from 'react'
import { Switch, Route } from "react-router-dom"
import Payments from './payments/payments.component'
import Invoices from './invoices/invoices.component'

const Billing = () => {
    return (
        <div id="billing" className="billing">
            <Switch>
                <Route path="/billing/payments" component={Payments} />
                <Route path="/billing/invoices" component={Invoices} />
            </Switch>
        </div>
    )
}

export default Billing
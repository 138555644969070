import React from 'react'
import './item.styles.scss'

const Item = props => {

    const membershipLabel = (type) => {
        switch (type) {
            case "0":
                return "Premium"
            case "1":
                return "Ambassador"
            default:
                return "None"
        }
    }

    return (
        <div className="item">
            <h4>Name: {props.name}</h4>
            <p>Email: {props.username}</p>
            <p>Membership: {membershipLabel(props.type)}</p>
            {
                props.type === false && <p className="actions">
                    <span onClick={() => props.onActivateMembership(props.id)}>Activate Membership</span>
                </p>
            }
        </div>
    )
}

export default Item
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Eastcoins from './eastcoins.component'
import CreditEastcoin from './credit/credit.component'

const EastcoinController = () => {
    return (
        <div className="eastcoins">
            <Switch>
                <Route path="/products/eastcoins/credit" component={CreditEastcoin} />
                <Route path="/products/eastcoins" component={Eastcoins} />
            </Switch>
        </div>
    )
}

export default EastcoinController
const DefaultStore = {
    number: "",
    place: "",
    country: {
        value: "",
        label: ""
    },
    issueDate: "",
    expiryDate: ""
}

export default DefaultStore
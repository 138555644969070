import React from 'react'
import './item.styles.scss'

const Item = props => {
    return (
        <div className="item">
            <img alt="" src={props.photo}/>
            <div>
                <h4>{props.firstname} {props.lastname}</h4>
                <p>{props.email}</p>
                <p>{props.type === '0'? 'Premium': 'Ambassador'}</p>
                <p>{new Date(props.creationDate).toString("dd MMM, yyyy")}</p>
            </div>
        </div>
    )
}

export default Item
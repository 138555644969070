import React from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react'
import './create.styles.scss'
import { Validation } from '../../../core/validations/validation'
import { StringValidationRule, EmailValidationRule } from '../../../core/validations/validation-rules'
import { GetAuthorizationToken } from '../../../util/AuthHelper'
import { API_ENDPOINT } from '../../../config'
import { createNewsletter, createTestNewsletter } from './helper'

class CreateNewsletter extends React.Component {

    constructor(props) {
        super(props)
        this.state = this.getDefaultState()
    }

    getDefaultState = () => {
        return (
            {
                working: false,
                subject: "",
                email: "",
                body: "",
                errors: {}
            }
        )
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.isValid() && this.save()
    }

    isValid() {
        let errors = {}
        let validation = new Validation()
        validation.addValidationRule(StringValidationRule, this.state.subject, (error) => errors.subject = error, { min: { value: 5, error: "Too short" }, max: { value: 100, error: "Too long" } })
        validation.addValidationRule(StringValidationRule, this.state.body, (error) => errors.body = error, { min: { value: 20, error: "Too short" }, max: { value: 50000, error: "Too long" } })
        this.setState({
            ...this.state,
            errors: errors
        })
        return validation.validate()
    }

    save = () => {
        this.setState({
            ...this.state,
            working: true
        })
        createNewsletter(this)
    }

    onNewsletterCreated = response => {
        this.setState({
            ...this.state,
            working: false,
            ...response
        })
        this.props.history.push('/apps/newsletters')
    }

    onError = message => {
        this.setState({
            ...this.state,
            working: false
        })
        alert(message)
    }

    sendTest = () => {
        if(!this.isValid()){
            return
        }
        if(!EmailValidationRule.validate(this.state.email, (error) => error.length > 0? alert('Invalid Email'): '')){
            return
        }
        this.setState({
            ...this.state,
            working: true,
            email: ""
        })
        createTestNewsletter(this)
    }

    onNewsletterTestCreated = response => {
        this.setState({
            ...this.state,
            working: false
        })
        alert('sent')
    }

    render() {
        return (
            <>
                <Link className="btn" to="/apps/newsletters">Newsletters</Link>
                <div className="card create-newsletter">
                    <h4>Create Newsletter</h4>
                    <form method="post" onSubmit={this.onSubmit}>
                        <div className="inputs">
                            <div className="row" id="subject" style={{ gridTemplateColumns: "1fr" }}>
                                <div className="form-input">
                                    <input type="text" name="subject" placeholder="Newsletter Subject" value={this.state.subject} onChange={this.onChange} />
                                    <span className="error">{this.state.errors.subject}</span>
                                </div>
                            </div>
                            <div className="editor">
                                <CKEditor
                                    data={this.state.body}
                                    config={{
                                        allowedContent: true,
                                        filebrowserUploadUrl: API_ENDPOINT + '/api/admin/newsletters/upload-image',
                                        filebrowserUploadMethod: 'xhr',
                                        fileTools_requestHeaders: {
                                            Authorization: GetAuthorizationToken()
                                        }
                                    }}
                                    onChange={(event) => {
                                        const data = event.editor.getData();
                                        this.onChange({
                                            target: {
                                                name: "body",
                                                value: data
                                            }
                                        })
                                    }}
                                />
                                <span className="error">{this.state.errors.body}</span>
                            </div>
                        </div>
                        <div className="group-test">
                            <input type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.onChange} />
                            <span onClick={this.sendTest}>Send Test</span>
                        </div>
                        <div className="actions">
                            <Link to={'/apps/newsletters'}>Back</Link>
                            {
                                this.state.working ?
                                    <span className="btn-wait"></span> :
                                    <button className="btn" type="submit">Submit</button>
                            }
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

export default CreateNewsletter
import React from 'react'
import { withEmit } from "react-emit"
import './search-box.styles.scss'
import searchIcon from '../../../assets/icons/search.svg'
import { SEARCH_CHANGED } from '../../events/header/search-box'

class SearchBox extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            search: ""
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
        this.props.emit(SEARCH_CHANGED, e.target.value.toLowerCase());
    }

    render(){
        return (
            <div className="search">
                <input name="search" autoComplete="off" onChange={this.onChange}/>
                <img src={searchIcon} alt="" />
            </div>
        )
    }
}

export default withEmit(SearchBox)
import { AUTH_SAVE_PROFILE } from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case AUTH_SAVE_PROFILE:
            return saveProfile(state, action.payload)
        default:
            return state
    }
}

const saveProfile = (state, payload) => {
    window.sessionStorage.setItem("auth", "value");
    state = payload.user
    localStorage.setItem('auth', payload);
    return state
}

export default Reducer
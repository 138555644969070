import {getRequest} from '../../../../util/RequestHelper'

export const GetRegistrations = instance => {
    getRequest({
        url: "/api/admin/users",
        success: response => instance.setState({
            ...instance.state,
            working: false,
            items: response.users
        }),
        failure: error => instance.setState({
            ...instance.state,
            working: false,
            items: []
        }, console.log(error))
    })
}
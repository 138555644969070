import React from 'react'
import Form from './form.component'
import Service from './credit.service'

class Credit extends React.Component {
    constructor(props) {
        super(props)
        this.state = this.getDefaultState()
    }

    getDefaultState = () => {
        return {
            tab: 0,
            working: false,
            message: "",
            username: "",
            value: "",
            errors: {}
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        if(this.isValid()){
            Service.creditEastcoin(this)
        }
    }

    isValid = () => {
        if(this.state.username.length < 5){
            alert('enter username')
            return false
        }
        else if(this.state.value.length  === 0){
            alert('enter value')
            return false
        }
        return true
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onCredited = () => {
        this.setState({
            ...this.state,
            message: "credited",
            working: false
        })
    }

    onCreditError = message => {
        this.setState({
            ...this.state,
            message: message,
            working: false
        })
    }

    render() {
        return (
            <div className="upload card">
               <Form onSubmit={this.onSubmit} onChange={this.onChange} onFileChange={this.onFileChange} onCheckboxChange={this.onCheckboxChange} {...this.state} />
            </div>
        )
    }
}

export default Credit

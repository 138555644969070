import React from 'react'
import { withRouter } from 'react-router-dom'
import { withEmit } from "react-emit"
import Item from './item/item.component'
import { GetRegistrations } from './helper'
import { SEARCH_CHANGED } from '../../../events/header/search-box'

class RegistrationList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: true,
            filter: ""
        }
    }

    componentDidMount() {
        GetRegistrations(this)
        this.props.on(SEARCH_CHANGED, (data) => {
            this.setState({
                ...this.state,
                filter: data
            })
        })
    }

    componentWillUnmount(){
        this.props.off(SEARCH_CHANGED)
    }

    onActivateMembership = (id) => {
        let response = window.confirm("Do you really want to do this?\nThis action cannot be undone")
        if(response === true){
            this.props.history.push('/users/registrations/activate/membership/' + id)
        }
    }

    render() {
        if (this.state.working) {
            return (
                <div>loading...</div>
            )
        }
        return (
            <div className="items">
                {
                    this.state.items.filter(item => item.username.toLowerCase().includes(this.state.filter) || item.name.toLowerCase().includes(this.state.filter)).map((item, key) => <Item key={key} {...item} onActivateMembership={this.onActivateMembership} />)
                }
            </div>
        )
    }
}

export default withEmit(withRouter(RegistrationList))
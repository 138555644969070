import { combineReducers } from 'redux'
import personal from './personal/reducer'
import education from './education/reducer'
import other from './other/reducer'
import passport from './passport/reducer'
import sponsor from './sponsor/reducer'
import travel from './travel/reducer'
import work from './work/reducer'
import data from './data/reducer'


const Reducer = combineReducers({
    personal,
    education,
    other,
    passport,
    sponsor,
    travel,
    work,
    data

})

export default Reducer
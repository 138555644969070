import {getRequest, postRequest} from '../../../util/RequestHelper'

const MeetingsHelper = {
    getMeetings: instance => {
        getRequest({
            url: "/api/admin/media/meetings",
            success: response => instance.onMeetingsLoaded(response),
            6000: message => instance.onMeetingsLoadingError(message),
            failure: error => instance.onMeetingsLoadingError(error)
        })
    },
    deleteMeeting: (instance, meetingId) => {
        postRequest({
            url: "/api/admin/media/meetings/delete",
            data: {
                id: meetingId
            },
            success: response => instance.onMeetingDeleted(meetingId),
            6000: message => instance.onMeetingDeleteError(meetingId, message),
            failure: error => instance.onMeetingDeleteError(meetingId, error)
        })
    }
}

export default MeetingsHelper
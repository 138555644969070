import React from 'react'
import { Switch, Route } from "react-router-dom"
import NewsletterController from './newsletters/controller.component'
import VideoController from './video/controller.component'
import MeetingController from './meeting/controller.component'

const AppController = () => {
    return (
        <div id="apps" className="apps">
            <Switch>
                <Route path="/apps/newsletters" component={NewsletterController} />
                <Route path="/apps/videos" component={VideoController} />
                <Route path="/apps/meetings" component={MeetingController} />
            </Switch>
        </div>
    )
}

export default AppController
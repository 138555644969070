import React from 'react'
import { Link } from 'react-router-dom'
import { getNewsletters } from './helper'
import Newsletter from './newsletter/newsletter.component'

class Newsletters extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            working: true
        }
    }

    componentDidMount() {
        getNewsletters(this)
    }

    onNewslettersReceived = response => {
        this.setState({
            ...this.state,
            working: false,
            ...response
        })
    }

    onError = message => {
        alert(message)
    }

    render() {
        if (this.state.working) {
            return (
                <div>loading...</div>
            )
        }
        return (
            <>
                <Link className="btn" to="/apps/newsletters/create">Create Newsletter</Link>
                <div className="newsletters">
                    {
                        this.state.newsletters.map((newsletter, key) => <Newsletter key={key} {...newsletter} />)
                    }
                </div>
                {
                    this.state.newsletters.length === 0 && <p>No Newsletters</p>
                }
            </>
        )
    }
}

export default Newsletters
import {postRequest} from '../../../util/RequestHelper'

export const LoginHelper = instance => {
    postRequest({
        url: "/api/admin/auth/login",
        data: {
            username: instance.state.username,
            password: instance.state.password
        },
        success: response => instance.onAuthenticationSuccess(response),
        5000: response => instance.onAuthenticationFailure(response),
        failure: error => instance.onAuthenticationFailure(error)
    })
}
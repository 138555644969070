import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { FindOptionByValue } from '../../../../util/helper'
import Checkbox from '../../../inputs/checkbox/checkbox.component'
import { Hours, Minutues, Reminders, Durations } from './data'

const ScheduleForm = (props) => {
    return (
        <form className="schedule" onSubmit={props.onSubmit}>
            <h4>Schedule Meeting</h4>
            {
                props.message.length > 0 && <h5 className="message">{props.message}</h5>
            }
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Topic</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="topic" value={props.topic} onChange={props.onChange} />
                        <span className="error">{props.errors.topic}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Start Date</label>
                    </div>
                    <div className="form-input">
                        <input type="date" name="startDate" value={props.startDate} onChange={props.onChange} />
                        <span className="error">{props.errors.startDate}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Start Time</label>
                    </div>
                    <div className="form-input">
                        <div className="start-time">
                            <div>
                                <Select name="startHour" options={Hours} onChange={(e) => {
                                    props.onChange({
                                        target: {
                                            name: "startHour",
                                            value: e.value
                                        }
                                    })
                                }} defaultValue={FindOptionByValue(Hours, props.startHour)} />
                                <span className="error">{props.errors.startHour}</span>
                            </div>
                            <div>
                                <Select name="startMinute" options={Minutues} onChange={(e) => {
                                    props.onChange({
                                        target: {
                                            name: "startMinute",
                                            value: e.value
                                        }
                                    })
                                }} defaultValue={FindOptionByValue(Minutues, props.startMinute)} />
                                <span className="error">{props.errors.startMinute}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Duration</label>
                    </div>
                    <div className="form-input">
                        <Select name="duration" options={Durations} onChange={(e) => {
                            props.onChange({
                                target: {
                                    name: "duration",
                                    value: e.value
                                }
                            })
                        }} defaultValue={FindOptionByValue(Durations, props.duration)} />
                        <span className="error">{props.errors.duration}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Reminder</label>
                    </div>
                    <div className="form-input">
                        <Select name="reminder" options={Reminders} onChange={(e) => {
                            props.onChange({
                                target: {
                                    name: "reminder",
                                    value: e.value
                                }
                            })
                        }} defaultValue={FindOptionByValue(Reminders, props.reminder)} />
                        <span className="error">{props.errors.reminder}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Password</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="password" value={props.password} onChange={props.onChange} />
                        <span className="error">{props.errors.password}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Newsletter Subject</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="newsletterSubject" value={props.newsletterSubject} onChange={props.onChange} />
                        <span className="error">{props.errors.newsletterSubject}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Agenda</label>
                    </div>
                    <div className="form-input">
                        <textarea type="text" name="agenda" value={props.agenda} onChange={props.onChange}></textarea>
                        <span className="error">{props.errors.agenda}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label"></div>
                    <div className="row-checkboxes">
                        <Checkbox text="Premium Members Only" name="premiumMember" checked={props.premiumMember} onChange={(name, checked) => props.onCheckboxChange(name, checked)} />
                        <Checkbox text="Host Video" name="hostVideo" checked={props.hostVideo} onChange={(name, checked) => props.onCheckboxChange(name, checked)} />
                        <Checkbox text="Participant Video" name="participantVideo" checked={props.participantVideo} onChange={(name, checked) => props.onCheckboxChange(name, checked)} />
                        <Checkbox text="Join Before Host" name="joinBeforeHost" checked={props.joinBeforeHost} onChange={(name, checked) => props.onCheckboxChange(name, checked)} />
                    </div>
                </div>
            </div>
            <div className="actions">
                <Link to={'/apps/meetings'}>Back</Link>
                {
                    props.working ?
                        <span className="btn-wait"></span> :
                        <button className="btn" type="submit">Save</button>
                }
            </div>
        </form>
    )
}

export default ScheduleForm
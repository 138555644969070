import React from 'react'
import './newsletter.styles.scss'

const Newsletter = (props) => {
    return (
        <div className="newsletter">
            <p><strong>{props.subject}</strong></p>
            <p>{new Date(props.creationDate).toString("dd MMM, yyyy")}</p>
            <div className="summary">
                <p>
                    Total<br/>
                    <span>{props.total}</span>
                </p>
                <p>
                    Sent<br/>
                    <span>{props.sent}</span>
                </p>
                <p>
                    Read<br/>
                    <span>{props.read}</span>
                </p>
                <p>
                    Blacklist <br/>
                    <span>{props.blacklist}</span>
                </p>
            </div>
        </div>
    )
}

export default Newsletter
import {getRequest} from '../../../util/RequestHelper'

export const PreviewHelper = {
    getApplication: instance => {
        getRequest({
            url: "/api/admin/applications/" + instance.props.match.params.number,
            success: response => instance.hanldeLoadResponse(response),
            failure: error => instance.handleLoadError(error)
        })
    }
}
import React from 'react'
import axios from 'axios'
import ProgressBar from '../../../ui/progress-bar/progress-bar.component'

class Uploading extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            progress: 0
        }
    }

    componentDidMount(){
        this.upload()
    }

    upload = () => {
        let config = {
            onUploadProgress: this.onProgressChange
          }
        axios.put(this.props.url, this.props.value, config)
            .then(response => this.hanldeUploadResponse(response))
            .catch(error => this.handleUploadError(error))
    }

    onProgressChange = (event) => {
        this.setState({
            ...this.state,
            progress: Math.round( (event.loaded * 100) / event.total )
        })
    }

    hanldeUploadResponse(response) {
        if (response.status === 200) {
            this.props.onUploadCompleted(this.props.name, response)
        }
    }

    handleUploadError(error) {
        console.log("Error: " + error)
    }

    render(){
        return(
            <div className="uploading">
                <h4>Uploading {this.props.title}</h4>
                <ProgressBar progress={this.state.progress}/>
            </div>
        )
    }
}

export default Uploading
import React from 'react'
import { Link } from 'react-router-dom'
import Upload from './upload/upload.component'

const VideoUploader = () => {
    return (
        <>
            <Link className="btn" to="/apps/videos">Videos</Link>
            <div className="uploader">
                <Upload/>
            </div>
        </>
    )
}

export default VideoUploader
import { createStore } from 'redux'
import Reducer from '../reducers/reducer'
import DefaultMenuStore from './defaults/menu'
import DefaultAuthStore from './defaults/auth'
import DefaultApplicationStore from './defaults/application/store'

const store = createStore(Reducer, {
    "menu": DefaultMenuStore,
    "auth": DefaultAuthStore,
    "application": DefaultApplicationStore
})

export default store
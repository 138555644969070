import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Meetings from './meetings.component'
import Schedule from './schedule/schedule.component'

const MeetingController = () => {
    return (
        <div className="meetings">
            <Switch>
                <Route path="/apps/meetings/schedule" component={Schedule} />
                <Route exact path="/apps/meetings" component={Meetings} />
            </Switch>
        </div>
    )
}

export default MeetingController
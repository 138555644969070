import React from 'react'
import { FindOptionByValue } from '../../../util/helper'
import YesNoOptions from '../../../assets/data/yes-no-options'
import TravelModeOptions from '../../../assets/data/travel-mode-options'

const Travel = (props) => {
    return (
        <>
            <div className="field">
                <label>Departure Date:</label>
                <span>{new Date(props.departureDate).toString("dd MMM, yyyy")}</span>
            </div>
            <div className="field">
                <label>Return Date:</label>
                <span>{new Date(props.returnDate).toString("dd MMM, yyyy")}</span>
            </div>
            <div className="field">
                <label>Adults:</label>
                <span>{props.adults}</span>
            </div>
            <div className="field">
                <label>Children:</label>
                <span>{props.chlidren}</span>
            </div>
            <div className="field">
                <label>Require Visa:</label>
                <span>{FindOptionByValue(YesNoOptions, props.requireVisa).label}</span>
            </div>
            <div className="field">
                <label>Require Tour Guide:</label>
                <span>{FindOptionByValue(YesNoOptions, props.requireTourGuide).label}</span>
            </div>
            <div className="field">
                <label>Budget:</label>
                <span>${props.budget}</span>
            </div>
            <div className="field">
                <label>Tour Duration:</label>
                <span>{props.tourDuration} days</span>
            </div>
            <div className="field">
                <label>Travel Mode:</label>
                <span>{FindOptionByValue(TravelModeOptions, props.travelMode).label}</span>
            </div>
            <div className="field">
                <label>Other Travel Mode:</label>
                <span>{props.otherTravelMode}</span>
            </div>
            <div className="field span-2">
                <span><label>Preferred School</label><br />{props.preferredSchool}</span>
            </div>
            <div className="span-2">
                <div className="field">
                    <label>Travel History</label><br />
                </div>
                <div dangerouslySetInnerHTML={{ __html: props.travelHistory }}></div>
            </div>
            <div className="span-2">
                <div className="field">
                    <label>Additional Info</label><br />
                </div>
                <div dangerouslySetInnerHTML={{ __html: props.additionalInfo }}></div>
            </div>
        </>
    )
}

export default Travel
import {postRequest} from '../../../../util/RequestHelper'

const Service = {
    creditEastcoin: instance => {
        postRequest({
            url: "/api/products/eastcoin/credit",
            data: {
                username: instance.state.username,
                value: instance.state.value
            },
            success: response => instance.onCredited(response),
            400: message => instance.onCreditError(message),
            failure: error => instance.onCreditError(error)
        })
    }
}

export default Service
import React from 'react'
import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import { withRouter } from 'react-router-dom'
import Form from './form.component'
import Success from './success.component'

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
            working: false,
            message: "Enter email address",
            username: ""
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        !this.state.working && this.resetPassword()
    }

    resetPassword = () => {
        this.setState({
            ...this.state,
            working: true
        })
        let data = {
            username: this.state.username
        }
        axios.defaults.withCredentials = true
        axios.post(API_ENDPOINT + "/api/auth/reset", data)
            .then(response => this.handlePasswordResetResponse(response))
            .catch(error => this.handlePasswordResetError(error))
    }

    handlePasswordResetResponse = (response) => {
        if (response.status === 200 && response.data.status === 200) {
            this.setState({
                ...this.reset,
                tab: 1
            })
        }
        else {
            alert('an error occurred')
        }
    }

    reset = {
        ...this.state,
        working: false,
        tab: 0,
        message: "Enter email",
        username: ""
    }

    handlePasswordResetError = (error) => {
        alert("An error occurred")
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <div className="signin">
                <div className="top">
                    <span>Recovery Password</span> <span>or <b onClick={() => this.props.history.push("/login")}>sign in</b></span>
                </div>
                {
                    this.state.tab === 0 ?
                        <Form onSubmit={this.onSubmit} onChange={this.onChange} {...this.state} />
                        :
                        <Success />
                }
            </div>
        )
    }
}

export default withRouter(ForgotPassword)
import { MENU_ITEM_CLICK, TOGGLE_SHOW_MENU } from './action-types'

const Reducer = (state = [], action) => {
    switch (action.type) {
        case MENU_ITEM_CLICK:
            return makeActive(state, action.payload)
        case TOGGLE_SHOW_MENU:
                return toggleShowMenu(state)
        default:
            return state
    }
}

const makeActive = (state, payload) => {
    state.menus.forEach((menu, key) => key === payload.key? menu.active = payload.active: menu.active = false)
    return state
}

const toggleShowMenu = (state) => {
    state.showMenu = !state.showMenu
    return state
}

export default Reducer
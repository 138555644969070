import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './secured.scss'
import Main from '../../components/main/main.component'
import Header from '../../components/header/header.component'

class Secured extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...this.props
        }
    }

    render() {
        if (sessionStorage.getItem('auth_name') === null) {
            this.props.history.push('/login')
            return (
                <>
                </>
            )
        }
        return (
            <div className="secured">
                <Header />
                <Main />
                <div className="footer">
                    <p className="copyright">© 2020 Eurufly Limited. All rights reserved.</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Secured))

import {postRequest} from '../../../util/RequestHelper'

export const getNewsletters = instance => {
    postRequest({
        url: "/api/admin/newsletters",
        data: {},
        success: response => instance.onNewslettersReceived(response),
        failure: error => instance.onError(error)
    })
}

export const createNewsletter = instance => {
    postRequest({
        url: "/api/admin/newsletters/create",
        data: {
            subject: instance.state.subject,
            body: instance.state.body
        },
        success: response => instance.onNewsletterCreated(response),
        failure: error => instance.onError(error)
    })
}

export const createTestNewsletter = instance => {
    postRequest({
        url: "/api/admin/newsletters/create",
        data: {
            subject: instance.state.subject,
            body: instance.state.body,
            email: instance.state.email
        },
        success: response => instance.onNewsletterTestCreated(response),
        failure: error => instance.onError(error)
    })
}
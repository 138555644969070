const DefaultStore = {
    photo: false,
    firstname: "",
    lastname: "",
    phone: "",
    gender: {
        value: "",
        label: ""
    },
    maritalStatus: {
        value: "",
        label: ""
    },
    dob: "",
    address: "",
    landmark: "",
    zipcode: "",
    city: "",
    state: "",
    country: {
        value: "",
        label: ""
    }
}

export default DefaultStore
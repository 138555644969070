const EnglishLanguageOptions = [
    {
        label: "Poor",
        value: "0"
    },
    {
        label: "Good",
        value: "1"
    },
    {
        label: "Excellent",
        value: "2"
    }
]

export default EnglishLanguageOptions
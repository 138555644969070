import { postRequest } from "../../util/RequestHelper"

export const DashboardHelper = {

    getAnalysis: dashboard => {
        postRequest({
            url: "/api/admin/analysis",
            success: response => dashboard.setState({
                ...dashboard.state,
                working: false,
                analysis: response.analysis
            }),
            failure: error => dashboard.setState({
                ...dashboard.state,
                working: false
            }, console.log(error))
        })
    }

}
import React from 'react'
import { Link } from 'react-router-dom'
import Checkbox from '../../../inputs/checkbox/checkbox.component'

const UploadForm = (props) => {
    return (
        <form className="upload" onSubmit={props.onSubmit}>
            <h4>Upload Video</h4>
            {
                props.message.length > 0 && <h5 className="message">{props.message}</h5>
            }
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Title</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="title" value={props.title} onChange={props.onChange} />
                        <span className="error">{props.errors.title}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Keywords</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="keywords" value={props.keywords} onChange={props.onChange} />
                        <span className="error">{props.errors.keywords}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Cover Image</label>
                    </div>
                    <div className="form-input">
                        <input type="file" name="image" accept=".jpg" onChange={props.onFileChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Video</label>
                    </div>
                    <div className="form-input">
                        <input type="file" name="video" accept=".mp4" onChange={props.onFileChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="form-label"></div>
                    <div className="row-checkboxes">
                        <Checkbox text="Premium Members Only" name="premiumMember" checked={props.premiumMember} onChange={(name, checked) => props.onCheckboxChange(name, checked)} />
                    </div>
                </div>
            </div>
            <div className="actions">
                <Link to={'/apps/videos'}>Back</Link>
                {
                    props.working ?
                        <span className="btn-wait"></span> :
                        <button className="btn" type="submit">Upload</button>
                }
            </div>
        </form>
    )
}

export default UploadForm
const DefaultStore = {
    showMenu: false,
    menus: [
        {
            text: "Dashboard",
            active: true,
            subItems: [

            ]
        },
        {
            text: "Applications",
            active: false,
            subItems: [
                {
                    url: "/applications",
                    text: "All Applications"
                }
            ]
        },
        {
            text: "Users",
            active: false,
            subItems: [
                {
                    url: "/users/registrations",
                    text: "Registrations"
                },
                {
                    url: "/users/membership",
                    text: "Membership"
                }
            ]
        },
        {
            text: "Earnings",
            active: false,
            subItems: [
                {
                    url: "/earnings/referrals",
                    text: "Referrals"
                }
            ]
        },
        {
            text: "Billing",
            active: false,
            subItems: [
                {
                    url: "/billing/invoices",
                    text: "Invoices"
                },
                {
                    url: "/billing/payments",
                    text: "Payments"
                }
            ]
        },
        {
            text: "Products",
            active: false,
            subItems: [
                {
                    url: "/products/eastcoins",
                    text: "Eastcoin"
                }
            ]
        },
        {
            text: "Apps",
            active: false,
            subItems: [
                {
                    url: "/apps/newsletters",
                    text: "Newsletters"
                },
                {
                    url: "/apps/videos",
                    text: "Videos"
                },
                {
                    url: "/apps/meetings",
                    text: "Meetings"
                }
            ]
        },
        {
            text: "Settings",
            active: false,
            subItems: [
                {
                    url: "/settings/change-password",
                    text: "Change Password"
                }
            ]
        }
    ]
}

export default DefaultStore
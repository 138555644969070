export const Hours = [
    {
        label: "00",
        value: 0
    },
    {
        label: "01",
        value: 1
    },
    {
        label: "02",
        value: 2
    },
    {
        label: "03",
        value: 3
    },
    {
        label: "04",
        value: 4
    },
    {
        label: "05",
        value: 5
    },
    {
        label: "06",
        value: 6
    },
    {
        label: "07",
        value: 7
    },
    {
        label: "08",
        value: 8
    },
    {
        label: "09",
        value: 9
    },
    {
        label: "10",
        value: 10
    },
    {
        label: "11",
        value: 11
    },
    {
        label: "12",
        value: 12
    },
    {
        label: "13",
        value: 13
    },
    {
        label: "14",
        value: 14
    },
    {
        label: "15",
        value: 15
    },
    {
        label: "16",
        value: 16
    },
    {
        label: "17",
        value: 17
    },
    {
        label: "18",
        value: 18
    },
    {
        label: "19",
        value: 19
    },
    {
        label: "20",
        value: 20
    },
    {
        label: "21",
        value: 21
    },
    {
        label: "22",
        value: 22
    },
    {
        label: "23",
        value: 23
    }
]

export const Minutues = [
    {
        label: "00",
        value: 0
    },
    {
        label: "01",
        value: 1
    },
    {
        label: "02",
        value: 2
    },
    {
        label: "03",
        value: 3
    },
    {
        label: "04",
        value: 4
    },
    {
        label: "05",
        value: 5
    },
    {
        label: "06",
        value: 6
    },
    {
        label: "07",
        value: 7
    },
    {
        label: "08",
        value: 8
    },
    {
        label: "09",
        value: 9
    },
    {
        label: "10",
        value: 10
    },
    {
        label: "11",
        value: 11
    },
    {
        label: "12",
        value: 12
    },
    {
        label: "13",
        value: 13
    },
    {
        label: "14",
        value: 14
    },
    {
        label: "15",
        value: 15
    },
    {
        label: "16",
        value: 16
    },
    {
        label: "17",
        value: 17
    },
    {
        label: "18",
        value: 18
    },
    {
        label: "19",
        value: 19
    },
    {
        label: "20",
        value: 20
    },
    {
        label: "21",
        value: 21
    },
    {
        label: "22",
        value: 22
    },
    {
        label: "23",
        value: 23
    },
    {
        label: "24",
        value: 24
    },
    {
        label: "25",
        value: 25
    },
    {
        label: "26",
        value: 26
    },
    {
        label: "27",
        value: 27
    },
    {
        label: "28",
        value: 28
    },
    {
        label: "29",
        value: 29
    },
    {
        label: "30",
        value: 30
    },
    {
        label: "31",
        value: 31
    },
    {
        label: "32",
        value: 32
    },
    {
        label: "33",
        value: 33
    },
    {
        label: "34",
        value: 34
    },
    {
        label: "35",
        value: 35
    },
    {
        label: "36",
        value: 36
    },
    {
        label: "37",
        value: 37
    },
    {
        label: "38",
        value: 38
    },
    {
        label: "39",
        value: 39
    },
    {
        label: "40",
        value: 40
    },
    {
        label: "41",
        value: 41
    },
    {
        label: "42",
        value: 42
    },
    {
        label: "43",
        value: 43
    },
    {
        label: "44",
        value: 44
    },
    {
        label: "45",
        value: 45
    },
    {
        label: "46",
        value: 46
    },
    {
        label: "47",
        value: 47
    },
    {
        label: "48",
        value: 48
    },
    {
        label: "49",
        value: 49
    },
    {
        label: "50",
        value: 50
    },
    {
        label: "51",
        value: 51
    },
    {
        label: "52",
        value: 52
    },
    {
        label: "53",
        value: 53
    },
    {
        label: "54",
        value: 54
    },
    {
        label: "55",
        value: 55
    },
    {
        label: "56",
        value: 56
    },
    {
        label: "57",
        value: 57
    },
    {
        label: "58",
        value: 58
    },
    {
        label: "59",
        value: 59
    }
]
export const Reminders = [
    {
        label: "No Reminder",
        value: 0
    },
    {
        label: "15 Minutes",
        value: 15 * 60
    },
    {
        label: "30 Minutes",
        value: 30 * 60
    },
    {
        label: "45 Minutes",
        value: 45 * 60
    },
    {
        label: "1 Hour",
        value: 60 * 60
    },
    {
        label: "2 Hours",
        value: 2 * 60 * 60
    },
    {
        label: "3 Hours",
        value: 3 * 60 * 60
    },
    {
        label: "5 Hours",
        value: 5 * 60 * 60
    },
    {
        label: "8 Hours",
        value: 8 * 60 * 60
    },
    {
        label: "10 Hours",
        value: 10 * 60 * 60
    },
    {
        label: "12 Hours",
        value: 12 * 60 * 60
    },
    {
        label: "1 Day",
        value: 24 * 60 * 60
    },
    {
        label: "2 Days",
        value: 2 * 24 * 60 * 60
    },
    {
        label: "3 Days",
        value: 3 * 24 * 60 * 60
    },
    {
        label: "5 Days",
        value: 5 * 24 * 60 * 60
    }
]
export const Durations = [
    {
        label: "15 Minutes",
        value: 15 * 60
    },
    {
        label: "30 Minutes",
        value: 30 * 60
    },
    {
        label: "45 Minutes",
        value: 45 * 60
    },
    {
        label: "1 Hour",
        value: 60 * 60
    },
    {
        label: "2 Hours",
        value: 2 * 60 * 60
    },
    {
        label: "3 Hours",
        value: 3 * 60 * 60
    },
    {
        label: "4 Hours",
        value: 4 * 60 * 60
    },
    {
        label: "5 Hours",
        value: 5 * 60 * 60
    },
    {
        label: "6 Hours",
        value: 6 * 60 * 60
    },
    {
        label: "7 Hours",
        value: 7 * 60 * 60
    },
    {
        label: "8 Hours",
        value: 8 * 60 * 60
    },
    {
        label: "9 Hours",
        value: 9 * 60 * 60
    },
    {
        label: "10 Hours",
        value: 10 * 60 * 60
    },
    {
        label: "12 Hours",
        value: 12 * 60 * 60
    }
]
import React from 'react'
import { Switch, Route } from "react-router-dom"
import Dashboard from '../dashboard/dashboard.component'
import Application from '../application/application.component'
import Menu from '../menu/menu.component'
import './main.styles.scss'
import Billing from '../billing/billing.component'
import AppController from '../apps/controller.component'
import UsersController from '../users/controller.component'
import ProductController from '../products/product.controller.'

const Main = () => {
    return (
        <div className="main">
            <Menu />
            <div className="content">
                <Switch>
                    <Route path="/billing" component={Billing} />
                    <Route path="/apps" component={AppController} />
                    <Route path="/products" component={ProductController} />
                    <Route path="/applications" component={Application} />
                    <Route path="/users" component={UsersController} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route exact path="/" component={Dashboard} />
                </Switch>
            </div>
        </div>
    )
}

export default Main
const DefaultStore = {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    relationship: "",
    selfSponsored: false
}

export default DefaultStore

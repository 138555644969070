import React from 'react'

const Success = () => {
    return (
        <p className="success">
            <strong>Password Reset</strong>
            <br/>
            You will receive a reset link if an account exist with the specified username.
        </p>
    )
}

export default Success